<template>
  <v-container>
    <v-row v-if="pageIsLoading">
      <v-col>
        <h3 class="text-center">

          <v-progress-circular indeterminate class="ml-3" color="primary" />
          Getting data . . .

        </h3>
      </v-col>
    </v-row>
    
    <v-alert 
      v-show="task_status_is_submitted" transition="fade-transition" color="success" type="success"
      style="z-index: 2; position: fixed; right: 15px; min-width: 300px" 
    >
      Successfully updated!
    </v-alert>

    <v-alert
      v-show="pageIsError" transition="fade-transition" color="error" type="error"
      style="z-index: 2; position: fixed; right: 15px; min-width: 300px"
    >
      An error occurred!
    </v-alert>

    <v-row v-if="!pageIsLoading">
      <v-col class="d-flex align-center">
        <label class="text-h5">
          <span> {{ stg_obj_tsk.stg_obj.stg_itm.kbn_stg.stage_name }}/ {{ stg_obj_tsk.stg_obj.title }}/ {{ stg_obj_tsk.title }}/ </span>
          <span v-if="kanban_type === 'Deal'" class="grey--text"> ( {{ stg_obj_tsk.stg_obj.stg_itm.deal.public_name }} ) </span> 
          <span v-if="kanban_type === 'Lender on boarding'" class="grey--text"> ( {{ stg_obj_tsk.stg_obj.stg_itm.company.name }} ) </span> 
        </label>
      </v-col>
    </v-row>

    <v-row v-if="!pageIsLoading">
      <v-col>
        <v-expansion-panels v-model="panel" multiple>
          <v-expansion-panel class="bordered">
            <v-expansion-panel-header class="d-flex align-center">
              <div>
                <v-icon>mdi-clipboard-text-outline</v-icon> Task Information 
              </div>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <v-simple-table>
                <thead>
                  <tr>
                    <th class="white--text text-center" :width="200">FIELDS</th> <th class="white--text text-center">DETAILS</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td> TASK NUMBER </td> <td class="pl-7"> <v-label> {{ stg_obj_tsk.id }} </v-label> </td>
                  </tr>

                  <tr>
                    <td> TITLE </td> 
                    <td> 
                      <v-text-field v-model="stg_obj_tsk.title" flat dense hide-details solo />
                    </td>
                  </tr>

                  <tr>
                    <td> DESCRIPTION </td> 
                    <td> 
                      <v-textarea v-model="stg_obj_tsk.description" rows="1" auto-grow flat dense hide-details solo /> 
                    </td>
                  </tr>

                  <tr>
                    <td> COUNT </td> <td class="pl-7"> <v-label> {{ stg_obj_tsk.count }} </v-label> </td>
                  </tr>

                  <tr>
                    <td> DUE DATE </td> 
                    <td> 
                      <v-text-field v-model="stg_obj_tsk.new_day_limit" flat dense hide-details solo type="date"/>
                    </td>
                  </tr>

                  <tr>
                    <td> STATUS </td> 
                    <td> 
                      <v-select 
                        v-model="stg_obj_tsk.status" :items="status_options" 
                        flat dense hide-details solo 
                      />
                    </td>
                  </tr>

                  <tr>
                    <td> PREDECESSOR </td>
                    <td> 
                      <v-select 
                        v-model="stg_obj_tsk.predecessor" :items="predecessors" 
                        hide-details solo flat dense
                      />
                    </td>
                  </tr>

                  <tr>
                    <td> ASSIGNEES </td> 
                    <td class="d-flex align-center">
                      <v-select 
                        v-model="stg_obj_tsk.selected_assignees" :items="assignees" item-value="id" item-text="username" 
                        flat dense hide-details multiple solo 
                      />
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <!-- <v-file-input
          class="mb-5" prepend-icon="" append-icon="mdi-paperclip" label="Attach Files" show-size counter multiple
        /> -->

        <v-textarea v-model="new_note" label="Add Note (optional)" auto-grow outlined/>
        
        <v-btn class="mr-5" title="Go back to kanban stage" @click="$router.go(-1)" >
          Back
        </v-btn>

        <v-btn :disabled="task_status_is_updating" @click="updateStatus">
          <v-progress-circular v-show="task_status_is_updating" class="mr-2" color="primary" indeterminate />
          Update Task 
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div>
                <v-icon> mdi-update </v-icon> UPDATE HISTORY:
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
        
              <v-data-table 
                :headers="note_headers" :items="this.stg_obj_tsk.notes" 
                class="elevation-1" style="background-color:#111;"
              >
                <template v-slot:item.createdAt="{ item }">
                  {{ formatDate(item.createdAt) }}
                </template>

                <template v-slot:item.status="{ item }">
                  {{ item.status }}
                </template>

                <template v-slot:item.note="{ item }">
                  {{ item.note }}
                </template>

                <template v-slot:item.user="{ item }">
                  {{ item.user.username }}
                </template>
              </v-data-table>

            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import API from '@/plugins/API';

export default {

  props: ['kanban_type', 'stage_item_id', 'stage_objective_id', 'stage_objective_task_id'],

  data: () => ({
    panel: [0],
    pageIsLoading: true,
    pageIsError: false,
    task_status_is_updating: false,
    task_status_is_submitted: false,

    new_note: '',

    assignees: [],

    stg_obj_tsk: {},

    predecessors: [ { value: 0, text: 'None' } ],

    status_options: ['To Do', 'On Progress', 'Done', 'Archived'],

    note_headers: [
      { text: 'Date', value: 'createdAt' },
      { text: 'Status', value: 'status' },
      { text: 'Note', value: 'note' },
      { text: 'User', value: 'user' },
    ],

  }),

  methods: {
    getDays(created_date) {
      // strapi date format to mm/dd/yyyy
      const dateFormatted = this.formatDate(created_date);

      // specify the start date in mm/dd/yyyy format
      const startDate = new Date(dateFormatted);

      // get the current system date
      const endDate = new Date();

      // calculate the time difference between the two dates
      const timeDiff = endDate.getTime() - startDate.getTime();

      // convert the time difference to days
      const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

      return daysDiff;
    },

    formatDate(date_value) {
      // create a new Date object from the string
      const dateObj = new Date(date_value);

      // extract the date components from the date object
      const year = dateObj.getFullYear();
      const month = dateObj.getMonth() + 1; // add 1 since month is zero-based
      const day = dateObj.getDate();

      // format the date components as mm/dd/yyyy
      const formattedDate = `${month}/${day}/${year}`;

      return formattedDate;
    },

    convertDayLimit() {
      const date = new Date(this.stg_obj_tsk.createdAt);
      date.setDate(date.getDate() + this.stg_obj_tsk.day_limit);

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const result = `${year}-${month}-${day}`;
      this.stg_obj_tsk.new_day_limit = result;
    },

    async updateStatus() {
      try {
        this.task_status_is_updating = true;

        // count from created date to new_day_limit
        // strapi date format to mm/dd/yyyy
        const dateFormatted = this.formatDate(this.stg_obj_tsk.createdAt);

        // specify the start date in mm/dd/yyyy format
        const startDate = new Date(dateFormatted);

        // get the day limit
        const endDate = new Date(this.stg_obj_tsk.new_day_limit);

        // calculate the time difference between the two dates
        const timeDiff = endDate.getTime() - startDate.getTime();

        // convert the time difference to days
        const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

        // update the day limit
        this.stg_obj_tsk.day_limit = daysDiff;

        const update_res = await API()
          .patch('api/kanban/update_stage_objective_task', 
            {
              user_id: this.$store.getters['Auth/getAuthUser'].id,
              stg_itm_id: this.stage_item_id,
              stg_obj_tsk: this.stg_obj_tsk,
              note: this.new_note
            }
          )
        
        if(update_res.status === 201) this.stg_obj_tsk.notes.push(update_res.data);

        this.task_status_is_updating = false;
        this.task_status_is_submitted = true;
        this.new_note = '';

        setTimeout(() => {
          this.task_status_is_submitted = false;
        }, 2000);

      } catch (error) {
        console.log(error);
        this.pageIsError = true;
        setTimeout(() => {
          this.pageIsError = false;
          this.task_status_is_updating = false;
        }, 2000);
      }
    },

    async readStageObjectiveTask() {
      try {
        this.pageIsLoading= true;

        // get stage objective task data
        const read_stg_obj_tsk = await API().get(`api/kanban/read_stage_objective_task/?stg_obj_tsk_id=${Number(this.stage_objective_task_id)}`);
        
        this.stg_obj_tsk = read_stg_obj_tsk.data;

        console.log('stage objective task: ', this.stg_obj_tsk);

        this.stg_obj_tsk.count = this.getDays(this.stg_obj_tsk.createdAt);

        this.convertDayLimit();

        // get stage objective data
        const read_stg_obj = await API().get(`api/kanban/read_stage_objective/?stg_obj_id=${Number(this.stage_objective_id)}`);

        // add predecessors to select options
        read_stg_obj.data.stg_obj_tsks.forEach((task) => {
          if(task.id !== this.stg_obj_tsk.id) {
            this.predecessors.push({
              value: task.order,
              text: task.title,
            });
          }
        });

        const read_internal_users = await API().get("api/kanban/get_internal_users");

        this.assignees = read_internal_users.data;
        
        this.pageIsLoading= false;
      } catch (error) {
        console.log(error);
        this.pageIsError = true;
        setTimeout(() => {
          this.pageIsError = false;
        }, 2000);
      }
    }
  },

  mounted() {
    this.readStageObjectiveTask();
  } 
};
</script>

<style lang="scss">
  .bordered{
    border: 1px solid #3c3c3c !important;
    background-color: transparent !important;
    padding: 5px;
  }
</style>
